.react-flow__controls {
  @apply absolute;
  @apply rounded-md;
  @apply shadow-md;
  @apply !left-4 !bottom-4;
}

.react-flow__controls-button {
  @apply flex justify-center items-center;
  @apply w-8 h-8 p-2;
  @apply scale-90 hover:scale-100 transition-transform;
  @apply !bg-white hover:bg-neutral-100;
  @apply cursor-pointer select-none;

  border-bottom: 1px solid theme('colors.neutral.100') !important;
}

.react-flow__controls-button svg {
  @apply w-3 h-3;
}
