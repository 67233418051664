@import "tailwindcss/base";
@import "./css/base/fonts.css";

@import "tailwindcss/components";
@import "./css/react-flow/core.css";
@import "./css/react-flow/controls.css";
@import "./css/react-flow/handles.css";
@import "./css/react-flow/edges.css";
@import "./css/react-flow/nodes.css";
@import "./css/react-flow/selection.css";

@import "tailwindcss/utilities";
@import "./css/scrollbar.css";

@import "allotment/dist/style.css";

:root {
  --sash-size: 8px;
  --sash-hover-size: 2px;
  --separator-border: theme('colors.neutral.200');
  --focus-border: theme('colors.neutral.300');
}

@layer components {
  .input {
    @apply w-full px-3.5 py-2.5 text-xs leading-tight transition-colors duration-300 border-2 rounded-md appearance-none hover:border-opacity-60 focus:border-opacity-70 text-neutral-900 focus:outline-none;
  }

  .input--input {
    @apply border-emerald-500 border-opacity-20;
  }
  .input--output {
    @apply border-indigo-500 border-opacity-20;
  }
  .input--neutral {
    @apply border-opacity-20 border-neutral-500;
  }
}

.collapse-css-transition {
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.node {
  @apply border-2 border-transparent;
}
.selected .node--input {
  @apply border-emerald-400 border-opacity-40;
}
.selected .node--filter {
  @apply border-blue-400 border-opacity-40;
}
.selected .node--output {
  @apply border-indigo-400 border-opacity-40;
}

html, body, #root {
  height: 100%;
  width: 100%;
}
