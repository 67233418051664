marker#react-flow__arrow > polyline {
  stroke: theme('colors.emerald.400');
}

marker#react-flow__arrowclosed > polyline {
  stroke: theme('colors.emerald.400');
  fill: theme('colors.emerald.400');
}

.react-flow__edge.selected .react-flow__edge-path {
  stroke: theme('colors.neutral.500');
}

.react-flow__edge.animated path {
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
}

.react-flow__edge.updating .react-flow__edge-path {
  stroke: theme('colors.neutral.500');
  stroke-opacity: 0.8;
}

.react-flow__edge-path {
  @apply hover:cursor-pointer;

  strokeWidth: 2;
  strokeLinecap: round;
}

.react-flow__edge-text {
  font-size: 10px;
}

.react-flow__edge-textbg {
  fill: white;
}

.react-flow__connection-path {
  strokeWidth: 2;
  stroke: theme('colors.neutral.500');
}
